.bl-activation-list {
    margin-top: -50px;
    @media screen and (max-width: 768px) {
        margin-top: -25px;
    }
    .grouped-date {
        font-weight: 400;
        line-height: 24px;
    }
    .ant-space {
        width: 100%;
    }
    .loading-more {
        text-align: center;
        .ant-btn {
            min-width: 341px;
        }
    }
    .empty {
        text-align: center;
        font-weight: 400;
        line-height: 120%;
        margin-top: 18px;
    }
    .list-items {
        position: relative;
        .total-count {
            position: absolute;
            right: 0;
            top: 14px;
            border-radius: 8px;
            background: #100514;
            background-blend-mode: multiply;
            backdrop-filter: blur(12px);
            padding: 10px;
            .text {
                font-family: "ALS Hauss";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                display: flex;
                align-items: center;
                span {
                    font-size: 24px;
                    font-weight: 700;
                    @media screen and (max-width: 768px) {
                        font-size: 18px;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                padding: 6px 10px;
                top: 20px;
            }
        }
    }
    .bl-activation-list-items {
        .bl-nps {
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 7px,
                    #ffd600 7px,
                    #ffd600 8px,
                    transparent 8px
                ),
                linear-gradient(to right, #ffd600, #ac36c4),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 7px,
                    #ac36c4 7px,
                    #ac36c4 8px,
                    transparent 8px
                ),
                linear-gradient(to bottom, #ac36c4, #580075),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 7px,
                    #580075 7px,
                    #580075 8px,
                    transparent 8px
                ),
                linear-gradient(to left, #580075, #efce73),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 7px,
                    #efce73 7px,
                    #efce73 8px,
                    transparent 8px
                ),
                linear-gradient(to top, #efce73, #ffd600);
            background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
            border: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-activation-list {
        .loading-more {
            .ant-btn {
                min-width: 100%;
            }
        }
        .empty {
            margin-top: 8px;
        }
    }
}
