.isFavorite {
    .bl-numbers-list {
        table {
            thead {
                th {
                    padding: 0 0 16px;
                    vertical-align: bottom;

                    &:first-child {
                        text-align: left;
                    }
                }
            }

            tbody {
                td {
                    vertical-align: top;

                    &:first-child {
                        text-align: left;
                        padding-left: 10px;
                        padding-top: 16px;
                    }

                    &:last-child {
                        padding-right: 10px;
                        position: static;
                    }
                }
                .ant-table-selection-column {
                    position: absolute;
                }
            }
        }
        .ant-collapse {
            * {
                transition: none;
            }
            .ant-collapse-item {
                .ant-collapse-header {
                    width: 100%;
                    padding-top: 0;
                }
                .ant-collapse-content-active,
                .ant-collapse-content {
                    max-width: calc(100% + 50px);
                    position: relative;
                    left: -50px;
                    width: calc(100% + 50px);
                    .ant-collapse-content-box {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.bl-numbers-list {
    &.page-loadong-skeleton {
        table {
            tbody {
                position: relative;
                top: 10px;
                .ant-table-cell.ant-table-selection-column {
                    opacity: 0;
                }
                tr {
                    height: 80px;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 10px;
                        margin: auto;
                        height: 1px;
                        background: rgba(207, 200, 219, 0.2);
                    }
                }
                td {
                    padding: 0 0 20px 0;
                    &:last-child {
                        position: static;
                    }
                }
            }
        }
        &.kanban {
            table {
                tbody {
                    top: 0;
                    tr {
                        height: auto;
                        &::after {
                            border: 1px solid rgba(207, 200, 219, 0.2);
                            top: 8px;
                            bottom: 8px;
                            height: auto;
                            background: transparent;
                        }
                    }
                    td {
                        padding: 0 0 20px 0;
                        &:last-child {
                            position: static;
                        }
                    }
                }
            }
        }
    }
    .button-skeleton {
        margin-top: 30px;
        .ant-skeleton-element {
            width: 100%;
            .ant-skeleton-button {
                height: 48px;
                width: 100%;
                background: linear-gradient(
                    90.72deg,
                    rgba(255, 255, 255, 0.1) 22.61%,
                    rgba(255, 255, 255, 0.025) 106.02%
                );
                border-radius: 8px;
            }
        }
    }
    .load-more {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        span {
            &:last-child {
                font-size: 14px;
            }
        }
    }

    .ant-pagination {
        display: none;
    }

    .wantBuy {
        font-size: 32px;
    }

    &.bl-numbers-in-booking,
    .ant-table-empty {
        table {
            tbody {
                td {
                    &:last-child {
                        position: static;
                    }
                }
            }
        }
    }

    table {
        thead {
            th {
                padding: 0 0 16px;
                vertical-align: bottom;

                &:first-child {
                    text-align: left;
                }
            }
        }

        tbody {
            td {
                padding: 16px 5px;

                &:first-child {
                    text-align: left;
                    padding-left: 10px;
                    padding-top: 25px;
                }

                &:last-child {
                    padding-right: 10px;
                    text-align: right;
                    position: absolute;
                    right: 0;
                }
                .bz-block-default {
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    padding: 24px 0 24px 20px;
                    border-radius: 12px;
                }
            }
            .ant-table-selection-column {
                position: absolute;
            }
        }
    }

    .bl-numbers-list-info {
        .dealer-id-kanban {
            display: none;
        }
    }

    &.kanban {
        .bl-numbers-list-info-phone {
            .dealer-id {
                display: none;
            }
        }

        .bl-numbers-list-info-description {
            .bz-typography {
                font-weight: 400;
            }
        }

        .ant-table {
            &:not(.ant-table-empty) {
                tbody {
                    tr {
                        td {
                            padding: 0;
                            &:last-child {
                                left: 0;
                            }

                            &:first-child {
                                position: absolute;
                                padding: 0;
                                z-index: 2;
                                width: 18px;
                            }

                            .bl-numbers-list-info {
                                > .ant-space {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    flex-direction: column;
                                    padding-left: 32px;
                                    gap: 4px !important;
                                }
                            }

                            &:nth-child(2) {
                                // margin-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }

        .bl-numbers-list-actions {
            padding-left: 32px;
            text-align: left;

            .lightning {
                display: none;
            }
            .btn-turbo {
                display: none;
            }
        }

        .bl-numbers-list-info-description {
            padding-right: 32px;
        }

        .bl-numbers-list-info {
            .lightning {
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 8px;
                margin-top: 0;
            }

            .dealer-id-kanban {
                display: block;
            }
        }
    }

    thead {
        tr {
            th {
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
    .ant-collapse {
        .ant-collapse-item {
            .ant-collapse-header {
                width: 655px;
                padding-top: 0;
                @media screen and (min-width: 768px) {
                    max-width: 400px;
                }
                @media screen and (min-width: 800px) {
                    max-width: 500px;
                }
                @media screen and (min-width: 900px) {
                    max-width: 600px;
                }
                @media screen and (min-width: 1024px) {
                    max-width: 450px;
                }
                @media screen and (min-width: 1100px) {
                    max-width: 500px;
                }
                @media screen and (min-width: 1200px) {
                    max-width: 600px;
                }
                @media screen and (min-width: 1300px) {
                    max-width: 655px;
                }
            }
            .ant-collapse-content-active,
            .ant-collapse-content {
                max-width: 734px;
                position: relative;
                left: -50px;
                .ant-collapse-content-box {
                    max-width: 734px;
                    .list-icons {
                        .row-list-service {
                            .col-list-service-desctop {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                .social-icons-row {
                                    border-bottom: 1px solid #fff;
                                    background-clip: content-box;
                                    padding-bottom: 6px;
                                    max-width: 285px;
                                }
                                .social-rest {
                                    background: linear-gradient(
                                        141.08deg,
                                        #efce73 25.79%,
                                        #fef0b7 63.66%,
                                        #cea86f 97.07%
                                    );
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    text-fill-color: transparent;
                                    font-family: "ALS Hauss";
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 100%;
                                    margin-bottom: 16px;
                                }
                                .bl-info-common-wrapper {
                                    .bg-value-int-gold {
                                        background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png)
                                                center center no-repeat,
                                            url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png)
                                                center center no-repeat;
                                        background-size: cover;
                                        background-blend-mode: color, normal;
                                        border-radius: 16px;
                                        width: 104px;
                                        max-width: 125px;
                                        height: 47px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .info-packet-value {
                                            color: #000;
                                            font-family: "ALS Hauss";
                                            font-size: 32px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 140%;
                                            .inline {
                                                color: #000;
                                                font-size: 16px !important;
                                                font-weight: 700 !important;
                                            }
                                        }
                                    }
                                    .col-info-packet {
                                        .info-packet-value {
                                            display: flex;
                                            align-items: center;
                                            .dark {
                                                color: #000;
                                                font-family: "ALS Hauss";
                                                font-size: 32px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 140%;
                                            }
                                            .gold {
                                                font-family: "ALS Hauss";
                                                font-size: 24px;
                                                font-style: normal;
                                                font-weight: 500;
                                                line-height: 140%;
                                            }
                                            .suffix {
                                                font-family: "ALS Hauss";
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                            }
                                        }
                                    }
                                }
                            }
                            .price-ios-rest,
                            .price-rest {
                                font-family: "ALS Hauss";
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 140%;
                                margin-top: 8px;
                                margin-bottom: 8px;
                                background: var(
                                    --gold-gradient-1,
                                    linear-gradient(
                                        171deg,
                                        #efce73 0%,
                                        #fef0b7 53.13%,
                                        #cea86f 100%
                                    )
                                );
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-size: 14px;
                            }
                            .price-ios,
                            .price-android {
                                font-family: "ALS Hauss";
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 140%;
                                color: #ffffff;
                                border-radius: 8px;
                                background: rgba(82, 8, 106, 0.3);
                                max-width: fit-content;
                                padding: 4px 8px;
                                margin-top: 8px;
                            }
                            .bl-info-common-wrapper {
                                .bg-value-int-gold {
                                    background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png)
                                            center center no-repeat,
                                        url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png)
                                            center center no-repeat;
                                    background-size: cover;
                                    background-blend-mode: color, normal;
                                    border-radius: 8px;
                                    width: 86px;
                                    max-width: 125px;
                                    height: 33px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                                    .info-packet-value {
                                        color: #000;
                                        font-family: "ALS Hauss";
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 140%;
                                        .inline {
                                            color: #000;
                                            font-size: 16px !important;
                                            font-weight: 700 !important;
                                        }
                                    }
                                }
                                .col-info-packet {
                                    .info-packet-value {
                                        display: flex;
                                        align-items: center;
                                        .dark {
                                            color: #000;
                                            font-family: "ALS Hauss";
                                            font-size: 24px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 140%;
                                        }
                                        .gold {
                                            font-family: "ALS Hauss";
                                            font-size: 20px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 140%;
                                        }
                                        .suffix {
                                            font-family: "ALS Hauss";
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .row-list-service {
        > .ant-col {
            width: 100%;
        }
        .ant-space {
            width: 100% !important;
        }
        .ant-col-18 {
            .bz-typography {
                margin-left: 8px;
            }
        }
        .ant-col {
            .ant-space {
                .ant-space-item {
                    .ant-row {
                        align-items: center;
                    }
                }
            }
        }
    }
    .ant-collapse-item-disabled {
        .ant-collapse-header {
            &:hover {
                cursor: default;
            }
            .ant-collapse-expand-icon {
                display: none;
            }
        }
    }
}

.list-container {
    &.grouped {
        .ant-table:not(.ant-table-empty) {
            thead {
                border: 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .mobile-grouped-dropdown-open {
        > .ant-space-item {
            &:nth-child(1) {
                margin-bottom: 216px !important;
                transition-duration: 1.7s;
            }
        }
    }

    .mobile-dropdown-open {
        thead {
            margin-bottom: 216px !important;
            transition-duration: 1.7s;
        }

        .ant-table {
            &.ant-table-empty {
                colgroup {
                    width: 100%;
                    display: block;
                }

                thead {
                    display: block;
                    width: 100%;
                    margin-bottom: 8px;
                    border-bottom: 1px solid #fff;

                    tr {
                        display: flex;
                        width: 100%;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;

                        th {
                            border: 0;
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                }

                tbody {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    margin: 0 -8px;

                    tr {
                        width: 100%;
                        display: block;

                        td {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .bl-numbers-list {
        table {
            thead {
                th {
                    vertical-align: middle;
                    padding: 0 0 8px;
                }
            }

            tbody {
                td {
                    vertical-align: top;
                }
            }
        }

        &.paid {
            &.kanban {
                .bl-numbers-list-actions {
                    > .ant-space {
                        flex-direction: column;
                    }
                }
            }
        }

        &.kanban {
            &.is-can-super-booking {
                .ant-table {
                    &:not(.ant-table-empty) {
                        tbody {
                            tr {
                                td {
                                    &:last-child {
                                        width: 150px;
                                        @media screen and (max-width: 576px) {
                                            width: 120px;
                                        }
                                    }
                                    &:nth-child(2) {
                                        width: calc(100% - 150px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ant-table {
                &:not(.ant-table-empty) {
                    tbody {
                        tr {
                            td {
                                &:last-child {
                                    width: 120px;
                                    text-align: right;
                                }

                                &:nth-child(2) {
                                    width: calc(100% - 125px);
                                }
                            }
                        }
                    }
                }
            }

            .bl-numbers-list-actions {
                padding-left: 0;
                text-align: right;

                .lightning {
                    display: block;
                }
                .btn-turbo {
                    display: flex;
                }
            }

            .bl-numbers-list-info-description {
                padding-right: 0;
            }
        }

        .bl-numbers-list-info {
            .lightning {
                opacity: 0 !important;
                width: 0 !important;
            }
            .ant-collapse {
                .ant-collapse-item {
                    .ant-collapse-header {
                        width: 100%;
                    }
                    .ant-collapse-content {
                        left: 0px;
                        min-width: 390px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .bl-numbers-list {
        .ant-table-container {
            .ant-table-content {
                .ant-table-row-level-0 {
                    .bl-numbers-list-info {
                        min-width: 320px;
                        width: 100%;
                        .ant-collapse-header {
                            width: 100%;
                            max-width: 655px;
                            .anticon-right {
                                top: 11px;
                                right: 119px;
                            }
                            .bl-numbers-list-info-phone {
                                margin-left: 10px;
                            }
                            .bl-numbers-list-info-description {
                                margin-left: 10px;
                                @media screen and (max-width: 410px) {
                                    margin-top: 10px;
                                }
                            }
                        }
                        .ant-collapse-content,
                        .ant-collapse-content-active {
                            width: 100%;
                            min-width: 380px;
                            margin-right: -55px;
                            left: -30px;
                            .ant-collapse-content-box {
                                width: 100%;
                                min-width: 380px;
                                margin-right: -55px;
                                .bz-block {
                                    border: none;
                                    .row-list-service {
                                        display: block;
                                        .ant-space {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .bz-block {
                            border: none;
                            padding-top: 0;
                            padding-bottom: 0;
                            .title-price-list {
                                margin: 10px 0 10px 24px;
                            }
                            .bl-limits,
                            .price-tariff {
                                margin-left: 24px;
                            }
                            .row-list-service {
                                .ant-space {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bl-numbers-list.kanban:not(.page-loadong-skeleton)
        .ant-table:not(.ant-table-empty)
        tbody
        tr
        td:nth-child(2) {
        width: calc(100% - 135px);
    }
    .bl-numbers-list.kanban:not(.page-loadong-skeleton)
        .ant-table:not(.ant-table-empty)
        tbody
        tr
        td:last-child {
        left: 13px;
    }
    .bl-numbers-list.kanban.is-can-super-booking:not(.page-loadong-skeleton)
        .ant-table:not(.ant-table-empty)
        tbody
        tr
        td:last-child {
        left: -13px;
    }
    .bl-numbers-list.kanban.is-can-super-booking:not(.page-loadong-skeleton)
        .ant-table:not(.ant-table-empty)
        .without-left {
        position: relative;
        right: -23px;
    }
}
@media screen and (max-width: 360px) {
    .bl-numbers-list {
        .ant-table-container {
            .ant-table-content {
                .ant-table-row-level-0 {
                    .bl-numbers-list-info {
                        .ant-collapse-content,
                        .ant-collapse-content-active {
                            width: 100%;
                            min-width: 370px;
                            margin-right: -55px;
                            left: -30px;
                            .ant-collapse-content-box {
                                width: 100%;
                                min-width: 370px;
                                margin-right: -55px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .bl-numbers-list {
        &.kanban {
            .ant-table {
                &:not(.ant-table-empty) {
                    tbody {
                        tr {
                            td {
                                &:nth-child(2) {
                                    width: 100%;
                                    margin-bottom: 8px;
                                }

                                &:last-child {
                                    width: 100%;
                                }

                                .bl-numbers-list-actions {
                                    > .ant-space {
                                        width: 100%;
                                        align-items: flex-start;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .bl-numbers-list-actions {
                padding-left: 32px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-list {
        &.kanban {
            .bl-numbers-list-info {
                .dealer-id-kanban {
                    display: none;
                }
            }
        }
    }
}
