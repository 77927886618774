.bl-news-discription {
    .bl-back {
        z-index: 99;
        margin-top: 0;
    }
    img {
        width: 100%;
        border-radius: 16px;
    }
    .text-news {
        p {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
        }
        strong {
            font-weight: 600;
            font-size: 14px;
            img {
                width: 100%;
            }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #fff;
            line-height: 26px;
            font-weight: 400;
        }
        img {
            cursor: pointer;
            height: auto !important;
            width: 40% !important;
            display: flex !important;
            justify-content: center !important;
            margin: 0px auto !important;
            border-radius: 16px !important;
        }
    }
    .anticon-clock-circle {
        margin-right: 5px;
    }
    .bl-news-discription-NumberClock {
        margin-right: 5px;
    }
    .images-news {
        .ant-image {
            display: block;
        }
        img {
            width: 58% !important;
            display: flex !important;
            justify-content: center !important;
            margin: 0px auto !important;
            border-radius: 16px !important;
        }
    }
}
.bl-modalphoto-news-wrapper {
    .ant-modal-content {
        padding: 0px !important;
        .ant-modal-close {
            right: 15px;
            position: fixed;
            opacity: 0;
            animation: fadeIn 0.5s ease-in-out 0.5s forwards;
            svg {
                color: #fff;
            }
        }
    }
    img {
        height: 100%;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        margin: 45px auto;
        .ant-modal-content {
            width: 95%;
            margin: 0 auto;
            .ant-modal-close {
                right: 0px;
            }
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-news-discription {
        .preview-text {
            text-align: center;
        }
        .bl-back {
            margin-top: 0;
        }
        .block-title {
            font-size: 32px !important;
            line-height: 100%;
            text-align: center;
            padding: 0 25px;
        }
        .images-news img {
            width: 100% !important;
            margin-bottom: 33px;
        }
        .published-date {
            text-align: center;
        }
        .text-news {
            iframe {
                width: 300px;
            }
        }
    }
}

@media screen and (max-width: 468px) {
    .bl-news-discription {
        .text-news {
            img {
                width: 100% !important;
            }
        }
    }
}
