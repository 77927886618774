.modalpage {
    overflow: hidden;
}
.bl-numbers-filters-mobile-modal {
    > .ant-space {
        width: 100%;
        .ant-space-item {
            width: 100%;
        }
    }
}
.bl-numbers-filter-mobile-form {
    &.modal-loading {
        .flex {
            display: flex;
        }
        .end {
            justify-content: flex-end;
        }
        .g5 {
            gap: 5px;
        }
        .g6 {
            gap: 6px;
        }
        .row {
            flex-direction: column;
        }
        .mmt10 {
            margin-top: -10px;
        }
        .big {
            flex: 0 0 116%;
            max-width: 116%;
        }
        .o0 {
            opacity: 0;
        }
        .mm8 {
            margin-block: -8px;
            * {
                max-width: 195px;
            }
        }
        .border {
            border: 1px solid rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(3px);
            border-radius: 8px;
            padding: 7px 20px;
            position: relative;
            @media screen and (max-width: 320px) {
                padding: 7px;
            }
            .ant-col {
                position: static;
            }
            .line {
                height: 22px !important;
                min-width: 56px;
                &.sm {
                    width: 22px;
                    min-width: auto;
                }
            }
            .ant-skeleton {
                overflow: initial;
            }
            .flex {
                height: 35px;
                align-items: center;
            }
            .ant-skeleton.rl {
                position: absolute;
                right: 20px;
                @media screen and (max-width: 320px) {
                    right: 7px;
                }
            }
        }
        .circle {
            border-radius: 100px;
            width: 22px;
            height: 22px;
            min-width: auto;
        }
        .ant-skeleton {
            overflow: hidden;
            .ant-skeleton-button {
                background: linear-gradient(
                    91.53deg,
                    rgba(255, 255, 255, 0.35) -9.38%,
                    rgba(255, 255, 255, 0) 99.81%
                );
                opacity: 0.45;
                overflow: hidden;
            }
        }
        .line {
            border-radius: 4px;
            &.bordered {
                .bordered {
                    position: relative;
                    background: linear-gradient(
                        163.27deg,
                        rgba(255, 255, 255, 0.1) 10.41%,
                        rgba(255, 255, 255, 0) 77.31%
                    );
                    opacity: 0.85;
                    backdrop-filter: blur(3px);
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 58%;
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        border-radius: 8px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        @media screen and (max-width: 1024px) {
                            right: 42%;
                        }
                        @media screen and (max-width: 768px) {
                            right: 42%;
                        }
                        @media screen and (max-width: 425px) {
                            right: 66%;
                        }
                        @media screen and (max-width: 375px) {
                            right: 58%;
                        }
                        @media screen and (max-width: 320px) {
                            right: 66%;
                        }
                    }
                }
            }
        }
        .middle {
            .line {
                height: 20px;
                width: 100%;
            }
            .round {
                width: 100%;
                border-radius: 43px;
                height: 40px;
            }
            &.long2 {
                .round {
                    height: 48px;
                }
                .line {
                    height: 22px;
                }
            }
        }
        .small {
            .line {
                height: 20px;
                width: 20px;
            }
        }
        .short2 {
            .line {
                height: 48px;
                border-radius: 8px;
            }
            &.long {
                * {
                    width: 100%;
                }
                .line {
                    &.bordered {
                        .bordered {
                            &::before {
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .fields-container {
        > .ant-space-item {
            &:nth-child(4) {
                * {
                    transition: none;
                }
                .bz-typography {
                    color: #828282;
                }
            }
        }
    }
    .ant-btn {
        border-radius: 43px;
        background-size: 100% 100%;
        background-repeat: round;
        &.mode {
            min-width: 100%;
            background-size: 100% 100%;
            background-repeat: round;
            border-radius: 43px;
            padding-top: 6px;
            img {
                width: 20px;
                margin-right: 8px;
                position: relative;
                top: -1px;
            }
        }
    }
    .bl-smart-field {
        .inputs {
            .ant-space-item {
                .ant-input {
                    border: 1px solid #e5e5e5;
                    border-radius: 8px;
                    height: 48px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #1f1f1f;
                }
            }
        }
    }
    .helper-text {
        text-align: left;
        font-weight: 400;
        line-height: 140%;
    }
    .bz-input-group-numbers {
        gap: 2px;
        justify-content: space-between;
        input {
            width: 30px;
            height: 52px;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #e5e5e5;
            backdrop-filter: blur(6px);
            border-radius: 8px;
            font-size: 28px;
            padding-top: 3px;
            &:nth-child(3) {
                margin-right: 10px;
            }
        }
        input::-webkit-input-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }
        input:-moz-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }
        input::-moz-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }
        input:-ms-input-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }
        input::-ms-input-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }
    }
    .ant-input-affix-wrapper,
    .ant-form-item-has-error
        :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input:hover,
    .ant-picker:hover {
        padding: 0 15px !important;
        .ant-input {
            padding: 0 !important;
            font-family: "ALS Hauss";
            font-size: 14px !important;
        }
    }
    input::-webkit-input-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }
    input:-moz-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }
    input::-moz-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }
    input:-ms-input-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }
    input::-ms-input-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }
    .cancel {
        .ant-btn {
            display: flex;
            margin: auto;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            align-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #000;
            // gap: 12px;
        }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin: 0;
    }

    .field-checkbox {
        .ant-form-item-control-input-content {
            overflow: hidden;
            height: 36px;

            .ant-checkbox-group {
                display: flex;
                gap: 4px;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: auto;
                padding-bottom: 10px;

                .ant-checkbox-wrapper {
                    background: #ffffff;
                    border: 1px solid #e5e5e5;
                    border-radius: 36px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0 12px;
                    height: 36px;

                    .ant-checkbox + span {
                        padding: 0;
                    }

                    &.ant-checkbox-wrapper-checked {
                        background: #642878;

                        .bz-typography {
                            color: #fff;
                        }
                    }

                    .ant-checkbox {
                        display: none;
                    }

                    .bz-typography {
                        font-weight: 500;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .ant-radio-wrapper + .ant-radio-wrapper {
        margin: 0;
    }

    .field-radio {
        .ant-form-item-control-input-content {
            overflow: hidden;
            height: 36px;

            .ant-radio-group {
                display: flex;
                gap: 4px;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: auto;
                padding-bottom: 10px;

                .ant-radio-wrapper {
                    background: #ffffff;
                    border: 1px solid #e5e5e5;
                    border-radius: 36px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0 12px;
                    height: 36px;
                    margin: 0;

                    .ant-radio + span {
                        padding: 0;
                    }

                    &.ant-radio-wrapper-checked {
                        background: #642878;

                        .bz-typography {
                            color: #fff;
                        }
                    }

                    .ant-radio {
                        display: none;
                    }

                    .bz-typography {
                        font-weight: 500;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .field-fake-select {
        position: relative;

        .ant-form-item-control {
            position: absolute;
            top: 7px;
            opacity: 0;
            min-width: 255px;
            width: 100%;
            height: 48px;
            border: 1px solid green;

            .ant-form-item-control-input {
                height: 100%;
            }
        }

        .ant-form-item-label {
            padding: 0;
            width: 100%;

            label {
                border: 1px solid #e5e5e5;
                border-radius: 8px;
                padding: 0 15px;
                height: 48px;
                display: flex;
                width: auto;
                align-items: center;
                justify-content: flex-start;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    display: block !important;
                    right: 12px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6.5L8 10.5L12 6.5' stroke='%23642878' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .ant-badge {
            margin-left: 4px;

            .ant-badge-count {
                box-shadow: none;
                background: #cfc8db;
                color: #000;
                width: 24px;
                height: 24px;
                line-height: 24px;
                border-radius: 16px;
                padding: 0;

                .current {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }

    .ant-space-item {
        &:nth-child(5) {
            margin-top: 24px;
        }

        &:nth-child(6) {
            margin-top: 16px;
        }
    }

    .ant-collapse {
        background: transparent;
        width: 100%;

        .save-black-svg {
            position: absolute;
            left: 16px;
            top: 99px;
        }

        .ant-collapse-content {
            .ant-collapse-content-box {
                padding: 4px 0 22px 0;

                .close-colapse-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .ant-collapse-item {
            width: 100%;

            .ant-collapse-header {
                .ant-collapse-expand-icon {
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                .ant-collapse-header-text {
                    text-align: start;
                    font-family: ALS Hauss;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    padding-left: 8px;
                }
            }
        }
    }

    .btn-apply-filters {
        .ant-badge {
            margin-left: 4px;

            .ant-badge-count {
                box-shadow: none;
                background: #cfc8db;
                color: #000;
                width: 24px;
                height: 24px;
                line-height: 24px;
                border-radius: 16px;
                padding: 0;

                .ant-scroll-number-only-unit {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }
}

.bl-filter-modal-mobile {
    overflow: hidden;

    .ant-space,
    .ant-space-item {
        width: 100%;
    }
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-content {
        padding: 40px 16px;
    }

    .ant-select-dropdown {
        padding: 0;

        .ant-select-item-option-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.col-filter {
    height: 48px;

    .ant-form-item-label {
        left: 0 !important;
    }
}

@media screen and (min-width: 576px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 4px;

            input {
                width: 40px;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 2px;

            input {
                width: 28px;
                font-size: 28px;
            }

            input::-webkit-input-placeholder {
                font-size: 28px;
            }

            input:-moz-placeholder {
                font-size: 28px;
            }

            input::-moz-placeholder {
                font-size: 28px;
            }

            input:-ms-input-placeholder {
                font-size: 28px;
            }

            input::-ms-input-placeholder {
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 2px;

            input {
                width: 26px;
                font-size: 26px;
            }

            input::-webkit-input-placeholder {
                font-size: 26px;
            }

            input:-moz-placeholder {
                font-size: 26px;
            }

            input::-moz-placeholder {
                font-size: 26px;
            }

            input:-ms-input-placeholder {
                font-size: 26px;
            }

            input::-ms-input-placeholder {
                font-size: 26px;
            }
        }
    }
}

@media screen and (max-width: 340px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 1px;

            input {
                width: 25px;
                font-size: 25px;
            }

            input::-webkit-input-placeholder {
                font-size: 25px;
            }

            input:-moz-placeholder {
                font-size: 25px;
            }

            input::-moz-placeholder {
                font-size: 25px;
            }

            input:-ms-input-placeholder {
                font-size: 25px;
            }

            input::-ms-input-placeholder {
                font-size: 25px;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 2px;

            input {
                width: 24px;
                font-size: 24px;
            }

            input::-webkit-input-placeholder {
                font-size: 24px;
            }

            input:-moz-placeholder {
                font-size: 24px;
            }

            input::-moz-placeholder {
                font-size: 24px;
            }

            input:-ms-input-placeholder {
                font-size: 24px;
            }

            input::-ms-input-placeholder {
                font-size: 24px;
            }
        }
    }
}

@media screen and (max-width: 310px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 1px;

            input {
                width: 22px;
                font-size: 22px;
            }

            input::-webkit-input-placeholder {
                font-size: 22px;
            }

            input:-moz-placeholder {
                font-size: 22px;
            }

            input::-moz-placeholder {
                font-size: 22px;
            }

            input:-ms-input-placeholder {
                font-size: 22px;
            }

            input::-ms-input-placeholder {
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width: 290px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 1px;

            input {
                width: 20px;
                font-size: 20px;
            }

            input::-webkit-input-placeholder {
                font-size: 20px;
            }

            input:-moz-placeholder {
                font-size: 20px;
            }

            input::-moz-placeholder {
                font-size: 20px;
            }

            input:-ms-input-placeholder {
                font-size: 20px;
            }

            input::-ms-input-placeholder {
                font-size: 20px;
            }
        }
    }
}

.bl-numbers-filter-mobile-form {
    .fields-container {
        > .ant-space-item {
            &:nth-child(4) {
                * {
                    transition: none;
                }

                .bz-typography {
                    color: #828282;
                }
            }
        }
    }

    .bl-smart-field {
        .inputs {
            .ant-space-item {
                .ant-input {
                    border: 1px solid #e5e5e5;
                    border-radius: 8px;
                    height: 48px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #1f1f1f;
                }
            }
        }
    }

    .helper-text {
        text-align: left;
        font-weight: 400;
        line-height: 140%;
    }

    .bz-input-group-numbers {
        gap: 2px;
        justify-content: space-between;

        input {
            width: 30px;
            height: 52px;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #e5e5e5;
            backdrop-filter: blur(6px);
            border-radius: 8px;
            font-size: 28px;
            padding-top: 3px;

            &:nth-child(3) {
                margin-right: 10px;
            }
        }

        input::-webkit-input-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }

        input:-moz-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }

        input::-moz-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }

        input:-ms-input-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }

        input::-ms-input-placeholder {
            font-family: "ALS Alumna";
            font-size: 28px;
        }
    }

    .ant-input-affix-wrapper,
    .ant-form-item-has-error
        :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input:hover,
    .ant-picker:hover {
        padding: 0 15px !important;

        .ant-input {
            padding: 0 !important;
            font-family: "ALS Hauss";
            font-size: 14px !important;
        }
    }

    input::-webkit-input-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }

    input:-moz-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }

    input::-moz-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }

    input:-ms-input-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }

    input::-ms-input-placeholder {
        font-family: "ALS Hauss";
        font-size: 14px;
    }

    .cancel {
        .ant-row {
            margin: 0;
            justify-content: space-between;

            .ant-col {
                padding: 0;
            }
        }

        .group-button {
            .save-btn {
                width: 100%;
                line-height: 140%;
                color: #642878;
                border: 1px solid #642878;
                border-radius: 8px;
                min-width: 160px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row-reverse;

                svg {
                    margin-left: 8px;
                }

                @media screen and (max-width: 360px) {
                    min-width: 150px;
                    svg {
                        margin-left: 6px;
                    }
                }
            }

            .reset-btn {
                width: 100%;
                line-height: 140%;
                color: #1f1f1f;
                border: 1px solid #cfc8db;
                border-radius: 8px;
                min-width: 160px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row-reverse;

                svg {
                    margin-left: 8px;
                }

                @media screen and (max-width: 360px) {
                    min-width: 150px;
                    svg {
                        margin-left: 6px;
                    }
                }
            }
        }

        .group-button-refresh {
            .ant-row {
                margin: 0 !important;
                justify-content: space-between;
                row-gap: 6px !important;

                .ant-col {
                    padding: 0 !important;
                }

                .ant-btn {
                    line-height: 140%;
                    color: #1f1f1f;
                    border: 1px solid #cfc8db;
                    border-radius: 8px;
                    min-width: 160px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row-reverse;

                    .ant-space {
                        gap: 0;
                    }

                    svg {
                        margin-left: -8px;
                    }
                }
            }

            .btn-save-new {
                padding-left: 8px;
            }
        }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin: 0;
    }

    .field-checkbox {
        .ant-form-item-control-input-content {
            overflow: hidden;
            height: 36px;

            .ant-checkbox-group {
                display: flex;
                gap: 4px;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: auto;
                padding-bottom: 10px;

                .ant-checkbox-wrapper {
                    background: #ffffff;
                    border: 1px solid #e5e5e5;
                    border-radius: 36px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0 12px;
                    height: 36px;
                    .ant-checkbox + span {
                        padding: 0;
                    }
                    &.ant-checkbox-wrapper-checked {
                        background: #642878;
                        .bz-typography {
                            color: #fff;
                        }
                    }
                    .ant-checkbox {
                        display: none;
                    }
                    .bz-typography {
                        font-weight: 500;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .ant-radio-wrapper + .ant-radio-wrapper {
        margin: 0;
    }
    .field-radio {
        .ant-form-item-control-input-content {
            overflow: hidden;
            height: 36px;
            .ant-radio-group {
                display: flex;
                gap: 4px;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: auto;
                padding-bottom: 10px;
                .ant-radio-wrapper {
                    background: #ffffff;
                    border: 1px solid #e5e5e5;
                    border-radius: 36px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0 12px;
                    height: 36px;
                    margin: 0;
                    .ant-radio + span {
                        padding: 0;
                    }
                    &.ant-radio-wrapper-checked {
                        background: #642878;
                        .bz-typography {
                            color: #fff;
                        }
                    }
                    .ant-radio {
                        display: none;
                    }
                    .bz-typography {
                        font-weight: 500;
                        line-height: 120%;
                        color: #828282;
                    }
                }
            }
        }
    }

    .field-fake-select {
        position: relative;
        .ant-form-item-control {
            position: absolute;
            top: 7px;
            opacity: 0;
            min-width: 255px;
            width: 100%;
            height: 48px;
            border: 1px solid green;

            .ant-form-item-control-input {
                height: 100%;
            }
        }
        .ant-form-item-label {
            padding: 0;
            width: 100%;

            label {
                border: 1px solid #e5e5e5;
                border-radius: 8px;
                padding: 0 15px;
                height: 48px;
                display: flex;
                width: auto;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    display: block !important;
                    right: 12px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6.5L8 10.5L12 6.5' stroke='%23642878' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }
        }
        .ant-badge {
            margin-left: 4px;
            .ant-badge-count {
                box-shadow: none;
                background: #cfc8db;
                color: #000;
                width: 24px;
                height: 24px;
                line-height: 24px;
                border-radius: 16px;
                padding: 0;
                .current {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }
    .ant-space-item {
        &:nth-child(5) {
            margin-top: 24px;
        }
        &:nth-child(6) {
            margin-top: 16px;
        }
    }
}

.bl-filter-modal-mobile {
    overflow: hidden;
    .ant-space,
    .ant-space-item {
        width: 100%;
    }
    .ant-modal-content {
        padding: 40px 16px;
    }
    .ant-select-dropdown {
        padding: 0;
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.col-filter {
    height: 48px;

    .ant-form-item-label {
        left: 0 !important;
    }
}

@media screen and (min-width: 576px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 4px;
            input {
                width: 40px;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 2px;
            input {
                width: 28px;
                font-size: 28px;
            }
            input::-webkit-input-placeholder {
                font-size: 28px;
            }
            input:-moz-placeholder {
                font-size: 28px;
            }
            input::-moz-placeholder {
                font-size: 28px;
            }
            input:-ms-input-placeholder {
                font-size: 28px;
            }
            input::-ms-input-placeholder {
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 2px;
            input {
                width: 26px;
                font-size: 26px;
            }
            input::-webkit-input-placeholder {
                font-size: 26px;
            }
            input:-moz-placeholder {
                font-size: 26px;
            }
            input::-moz-placeholder {
                font-size: 26px;
            }
            input:-ms-input-placeholder {
                font-size: 26px;
            }
            input::-ms-input-placeholder {
                font-size: 26px;
            }
        }
    }
}

@media screen and (max-width: 340px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 1px;
            input {
                width: 25px;
                font-size: 25px;
            }
            input::-webkit-input-placeholder {
                font-size: 25px;
            }
            input:-moz-placeholder {
                font-size: 25px;
            }
            input::-moz-placeholder {
                font-size: 25px;
            }
            input:-ms-input-placeholder {
                font-size: 25px;
            }
            input::-ms-input-placeholder {
                font-size: 25px;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 2px;
            input {
                width: 24px;
                font-size: 24px;
            }
            input::-webkit-input-placeholder {
                font-size: 24px;
            }
            input:-moz-placeholder {
                font-size: 24px;
            }
            input::-moz-placeholder {
                font-size: 24px;
            }
            input:-ms-input-placeholder {
                font-size: 24px;
            }
            input::-ms-input-placeholder {
                font-size: 24px;
            }
        }
    }
}

@media screen and (max-width: 310px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 1px;
            input {
                width: 22px;
                font-size: 22px;
            }
            input::-webkit-input-placeholder {
                font-size: 22px;
            }
            input:-moz-placeholder {
                font-size: 22px;
            }
            input::-moz-placeholder {
                font-size: 22px;
            }
            input:-ms-input-placeholder {
                font-size: 22px;
            }
            input::-ms-input-placeholder {
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width: 290px) {
    .bl-numbers-filter-mobile-form {
        .bz-input-group-numbers {
            gap: 1px;
            input {
                width: 20px;
                font-size: 20px;
            }
            input::-webkit-input-placeholder {
                font-size: 20px;
            }
            input:-moz-placeholder {
                font-size: 20px;
            }
            input::-moz-placeholder {
                font-size: 20px;
            }
            input:-ms-input-placeholder {
                font-size: 20px;
            }
            input::-ms-input-placeholder {
                font-size: 20px;
            }
        }
    }
}
