.bl-sim-modal {
    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        min-width: fit-content !important;
        height: fit-content;
        background: transparent;
        @media screen and (max-width: 768px) {
            min-width: fit-content !important;
        }
    }
    .title {
        text-align: center;
    }
    .subtitle {
        text-align: center;
        line-height: normal;
    }
}

@media screen and (max-width: 768px) {
    .bl-sim-modal {
        .ant-space {
            text-align: center;
            img {
                margin-bottom: 32px;
            }
        }
        .subtitle {
            font-size: 18px;
        }
    }
}
