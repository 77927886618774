.bl-modal-easy-delivery-sim {
    width: 760px !important;
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        border: 1px solid var(--white-250, rgba(255, 255, 255, 0.5));
        background: rgba(255, 255, 255, 0.05);
    }
    .bl-success {
        h2 {
            color: #fff !important;
        }
    }
    .bl-error {
        .bz-typography {
            color: #fff !important;
        }
    }
}
.bl-sim-form {
    .ant-btn:not(.ant-btn-text) {
        width: 100%;
    }
    .copy {
        display: none;
    }
    .ant-space {
        .ant-form-item {
            margin: 0;
            .copy-btn {
                min-width: 178px;
                width: 178px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }
            .ant-form-item-control {
                min-width: 0;
            }
        }
        .text-center {
            .cancel-btn {
                border-radius: 12px;
                border: 1px solid var(--White, #fff);
                height: 48px;
                min-width: 178px;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-sim-form {
        .cancel {
            margin-top: 16px;
        }
        .ant-space {
            flex-direction: column-reverse;
            .bz-typography {
                margin: 0;
            }
            .ant-btn {
                width: 100%;
            }
        }
    }
}
