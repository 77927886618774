.bl-modal-clear-esim {
    width: 760px !important;
    .ant-modal-content {
        border: 1px solid var(--white-250, rgba(255, 255, 255, 0.5));
        background: rgba(255, 255, 255, 0.05);
        text-align: center;
        padding-bottom: 80px;
        .ant-modal-close {
            top: 10px;
            right: 7px;
            .ant-modal-close-icon {
                svg {
                    color: #fff;
                    font-size: 28px;
                }
            }
        }
        .bl-remove-booking-modal {
            img {
                margin-top: 40px;
            }
            .title {
                text-align: center;
                font-family: "ALS Hauss";
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media screen and (max-width: 768px) {
                    white-space: pre-line;
                }
            }
            .bl-remove-booking-form {
                .ant-space {
                    .ant-form-item {
                        margin: 0;
                        .copy-btn {
                            min-width: 178px;
                            width: 178px;
                            @media screen and (max-width: 768px) {
                                width: 100%;
                            }
                        }
                        .ant-form-item-control {
                            min-width: 0;
                        }
                    }
                    .text-center {
                        .cancel-btn {
                            border-radius: 12px;
                            border: 1px solid var(--White, #fff);
                            height: 48px;
                            min-width: 178px;
                            color: #fff;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        flex-direction: column-reverse;
                        .bz-typography {
                            margin: 0;
                        }
                        .ant-btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            padding-bottom: 40px;
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-remove-booking-modal {
        .subtitle {
            text-align: left;
        }
    }
}
